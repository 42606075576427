const config = {
    // apiBaseUrl: "https://smartstayapi.qbatz.com",
    apiBaseUrl: "https://smartstaydevapi.s3remotica.com",
    // apiBaseUrl: "http://13.126.102.54:2001",
    // apiBaseUrl: "https://smartstaydev.s3remotica.com",
};

export default config;



// http://localhost:2001
// http://13.126.102.54:1010
// https://smartstayapi.qbatz.com
// http://13.126.102.54:2001





